/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateNote = /* GraphQL */ `
  subscription OnCreateNote {
    onCreateNote {
      id
      message
      owner
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateNote = /* GraphQL */ `
  subscription OnUpdateNote {
    onUpdateNote {
      id
      message
      owner
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteNote = /* GraphQL */ `
  subscription OnDeleteNote {
    onDeleteNote {
      id
      message
      owner
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateContactEmployee = /* GraphQL */ `
  subscription OnCreateContactEmployee {
    onCreateContactEmployee {
      id
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      employee {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateContactEmployee = /* GraphQL */ `
  subscription OnUpdateContactEmployee {
    onUpdateContactEmployee {
      id
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      employee {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteContactEmployee = /* GraphQL */ `
  subscription OnDeleteContactEmployee {
    onDeleteContactEmployee {
      id
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      employee {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateCompanyEmployee = /* GraphQL */ `
  subscription OnCreateCompanyEmployee {
    onCreateCompanyEmployee {
      id
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      employee {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCompanyEmployee = /* GraphQL */ `
  subscription OnUpdateCompanyEmployee {
    onUpdateCompanyEmployee {
      id
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      employee {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCompanyEmployee = /* GraphQL */ `
  subscription OnDeleteCompanyEmployee {
    onDeleteCompanyEmployee {
      id
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      employee {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateDivision = /* GraphQL */ `
  subscription OnCreateDivision {
    onCreateDivision {
      id
      label
      contacts {
        items {
          id
          division {
            id
            label
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      companies {
        items {
          id
          division {
            id
            label
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDivision = /* GraphQL */ `
  subscription OnUpdateDivision {
    onUpdateDivision {
      id
      label
      contacts {
        items {
          id
          division {
            id
            label
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      companies {
        items {
          id
          division {
            id
            label
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDivision = /* GraphQL */ `
  subscription OnDeleteDivision {
    onDeleteDivision {
      id
      label
      contacts {
        items {
          id
          division {
            id
            label
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      companies {
        items {
          id
          division {
            id
            label
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContactDivision = /* GraphQL */ `
  subscription OnCreateContactDivision {
    onCreateContactDivision {
      id
      division {
        id
        label
        contacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateContactDivision = /* GraphQL */ `
  subscription OnUpdateContactDivision {
    onUpdateContactDivision {
      id
      division {
        id
        label
        contacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteContactDivision = /* GraphQL */ `
  subscription OnDeleteContactDivision {
    onDeleteContactDivision {
      id
      division {
        id
        label
        contacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateCompanyDivision = /* GraphQL */ `
  subscription OnCreateCompanyDivision {
    onCreateCompanyDivision {
      id
      division {
        id
        label
        contacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCompanyDivision = /* GraphQL */ `
  subscription OnUpdateCompanyDivision {
    onUpdateCompanyDivision {
      id
      division {
        id
        label
        contacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCompanyDivision = /* GraphQL */ `
  subscription OnDeleteCompanyDivision {
    onDeleteCompanyDivision {
      id
      division {
        id
        label
        contacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateContactUsForm = /* GraphQL */ `
  subscription OnCreateContactUsForm {
    onCreateContactUsForm {
      id
      type
      firstName
      lastName
      email
      phone
      state
      zipcode
      message
      searchFirstName
      searchLastName
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const onUpdateContactUsForm = /* GraphQL */ `
  subscription OnUpdateContactUsForm {
    onUpdateContactUsForm {
      id
      type
      firstName
      lastName
      email
      phone
      state
      zipcode
      message
      searchFirstName
      searchLastName
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const onDeleteContactUsForm = /* GraphQL */ `
  subscription OnDeleteContactUsForm {
    onDeleteContactUsForm {
      id
      type
      firstName
      lastName
      email
      phone
      state
      zipcode
      message
      searchFirstName
      searchLastName
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const onCreateDownloadFileForm = /* GraphQL */ `
  subscription OnCreateDownloadFileForm {
    onCreateDownloadFileForm {
      id
      type
      firstName
      lastName
      email
      productId
      productName
      searchFirstName
      searchLastName
      state
      zipcode
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const onUpdateDownloadFileForm = /* GraphQL */ `
  subscription OnUpdateDownloadFileForm {
    onUpdateDownloadFileForm {
      id
      type
      firstName
      lastName
      email
      productId
      productName
      searchFirstName
      searchLastName
      state
      zipcode
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const onDeleteDownloadFileForm = /* GraphQL */ `
  subscription OnDeleteDownloadFileForm {
    onDeleteDownloadFileForm {
      id
      type
      firstName
      lastName
      email
      productId
      productName
      searchFirstName
      searchLastName
      state
      zipcode
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const onCreateTradeProgramForm = /* GraphQL */ `
  subscription OnCreateTradeProgramForm {
    onCreateTradeProgramForm {
      id
      type
      firstName
      searchFirstName
      lastName
      searchLastName
      email
      title
      companyName
      searchCompanyName
      streetAddress
      city
      state
      zipcode
      website
      phone
      fileName
      fileUrl
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const onUpdateTradeProgramForm = /* GraphQL */ `
  subscription OnUpdateTradeProgramForm {
    onUpdateTradeProgramForm {
      id
      type
      firstName
      searchFirstName
      lastName
      searchLastName
      email
      title
      companyName
      searchCompanyName
      streetAddress
      city
      state
      zipcode
      website
      phone
      fileName
      fileUrl
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const onDeleteTradeProgramForm = /* GraphQL */ `
  subscription OnDeleteTradeProgramForm {
    onDeleteTradeProgramForm {
      id
      type
      firstName
      searchFirstName
      lastName
      searchLastName
      email
      title
      companyName
      searchCompanyName
      streetAddress
      city
      state
      zipcode
      website
      phone
      fileName
      fileUrl
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const onCreateSubmissionForm = /* GraphQL */ `
  subscription OnCreateSubmissionForm {
    onCreateSubmissionForm {
      id
      type
      firstName
      lastName
      email
      phone
      title
      companyName
      streetAddress
      city
      state
      zipcode
      country
      website
      fileName
      fileUrl
      message
      productId
      productName
      preferredStore
      industry
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateSubmissionForm = /* GraphQL */ `
  subscription OnUpdateSubmissionForm {
    onUpdateSubmissionForm {
      id
      type
      firstName
      lastName
      email
      phone
      title
      companyName
      streetAddress
      city
      state
      zipcode
      country
      website
      fileName
      fileUrl
      message
      productId
      productName
      preferredStore
      industry
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteSubmissionForm = /* GraphQL */ `
  subscription OnDeleteSubmissionForm {
    onDeleteSubmissionForm {
      id
      type
      firstName
      lastName
      email
      phone
      title
      companyName
      streetAddress
      city
      state
      zipcode
      country
      website
      fileName
      fileUrl
      message
      productId
      productName
      preferredStore
      industry
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateUserSession = /* GraphQL */ `
  subscription OnCreateUserSession {
    onCreateUserSession {
      id
      lastDownloadFormSeen
      lastContactFormSeen
      lastTradeProgramFormSeen
      lastNewsletterFormSeen
      lastAddContactFormSeen
      lastDownloadNotificationSeen
      lastContactNotificationSeen
      lastTradeProgramNotificationSeen
      lastNewsletterNotificationSeen
      lastAddContactNotificationSeen
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserSession = /* GraphQL */ `
  subscription OnUpdateUserSession {
    onUpdateUserSession {
      id
      lastDownloadFormSeen
      lastContactFormSeen
      lastTradeProgramFormSeen
      lastNewsletterFormSeen
      lastAddContactFormSeen
      lastDownloadNotificationSeen
      lastContactNotificationSeen
      lastTradeProgramNotificationSeen
      lastNewsletterNotificationSeen
      lastAddContactNotificationSeen
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserSession = /* GraphQL */ `
  subscription OnDeleteUserSession {
    onDeleteUserSession {
      id
      lastDownloadFormSeen
      lastContactFormSeen
      lastTradeProgramFormSeen
      lastNewsletterFormSeen
      lastAddContactFormSeen
      lastDownloadNotificationSeen
      lastContactNotificationSeen
      lastTradeProgramNotificationSeen
      lastNewsletterNotificationSeen
      lastAddContactNotificationSeen
      createdAt
      updatedAt
    }
  }
`;
export const onCreateActivity = /* GraphQL */ `
  subscription OnCreateActivity {
    onCreateActivity {
      id
      activityId
      type
      creatorId
      createdAt
      action
      activityType
      payload {
        name
        note
        target
        targetId
        recipientContacts {
          id
          name
        }
        recipientCompanys {
          id
          name
        }
        attendeeContacts {
          id
          name
        }
        attendeeEmployees {
          id
          name
        }
        attendeeCompanys {
          id
          name
        }
        recipientOthers
        subject
        date
      }
      updatedAt
      creator {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateActivity = /* GraphQL */ `
  subscription OnUpdateActivity {
    onUpdateActivity {
      id
      activityId
      type
      creatorId
      createdAt
      action
      activityType
      payload {
        name
        note
        target
        targetId
        recipientContacts {
          id
          name
        }
        recipientCompanys {
          id
          name
        }
        attendeeContacts {
          id
          name
        }
        attendeeEmployees {
          id
          name
        }
        attendeeCompanys {
          id
          name
        }
        recipientOthers
        subject
        date
      }
      updatedAt
      creator {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteActivity = /* GraphQL */ `
  subscription OnDeleteActivity {
    onDeleteActivity {
      id
      activityId
      type
      creatorId
      createdAt
      action
      activityType
      payload {
        name
        note
        target
        targetId
        recipientContacts {
          id
          name
        }
        recipientCompanys {
          id
          name
        }
        attendeeContacts {
          id
          name
        }
        attendeeEmployees {
          id
          name
        }
        attendeeCompanys {
          id
          name
        }
        recipientOthers
        subject
        date
      }
      updatedAt
      creator {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      recipientContacts {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      recipientCompanys {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      recipientEmployees {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      toOthers
      ccOthers
      bccOthers
      subject
      body
      createdAt
      updatedAt
      attachments {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      from {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      recipientContacts {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      recipientCompanys {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      recipientEmployees {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      toOthers
      ccOthers
      bccOthers
      subject
      body
      createdAt
      updatedAt
      attachments {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      from {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      recipientContacts {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      recipientCompanys {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      recipientEmployees {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      toOthers
      ccOthers
      bccOthers
      subject
      body
      createdAt
      updatedAt
      attachments {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      from {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateMessageContact = /* GraphQL */ `
  subscription OnCreateMessageContact {
    onCreateMessageContact {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateMessageContact = /* GraphQL */ `
  subscription OnUpdateMessageContact {
    onUpdateMessageContact {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteMessageContact = /* GraphQL */ `
  subscription OnDeleteMessageContact {
    onDeleteMessageContact {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateMessageCompany = /* GraphQL */ `
  subscription OnCreateMessageCompany {
    onCreateMessageCompany {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateMessageCompany = /* GraphQL */ `
  subscription OnUpdateMessageCompany {
    onUpdateMessageCompany {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteMessageCompany = /* GraphQL */ `
  subscription OnDeleteMessageCompany {
    onDeleteMessageCompany {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateMessageEmployee = /* GraphQL */ `
  subscription OnCreateMessageEmployee {
    onCreateMessageEmployee {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      employee {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateMessageEmployee = /* GraphQL */ `
  subscription OnUpdateMessageEmployee {
    onUpdateMessageEmployee {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      employee {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteMessageEmployee = /* GraphQL */ `
  subscription OnDeleteMessageEmployee {
    onDeleteMessageEmployee {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      employee {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreatePermission = /* GraphQL */ `
  subscription OnCreatePermission {
    onCreatePermission {
      id
      type
      rule
      permissionUserId
      viewActive
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      calendar {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdatePermission = /* GraphQL */ `
  subscription OnUpdatePermission {
    onUpdatePermission {
      id
      type
      rule
      permissionUserId
      viewActive
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      calendar {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeletePermission = /* GraphQL */ `
  subscription OnDeletePermission {
    onDeletePermission {
      id
      type
      rule
      permissionUserId
      viewActive
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      calendar {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateTask = /* GraphQL */ `
  subscription OnCreateTask {
    onCreateTask {
      id
      title
      due_date
      description
      linkType
      creatorId
      employeeId
      status
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateTask = /* GraphQL */ `
  subscription OnUpdateTask {
    onUpdateTask {
      id
      title
      due_date
      description
      linkType
      creatorId
      employeeId
      status
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteTask = /* GraphQL */ `
  subscription OnDeleteTask {
    onDeleteTask {
      id
      title
      due_date
      description
      linkType
      creatorId
      employeeId
      status
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateAnalytics = /* GraphQL */ `
  subscription OnCreateAnalytics {
    onCreateAnalytics {
      id
      createdAt
      siteVisit
      topProduct
      updatedAt
    }
  }
`;
export const onUpdateAnalytics = /* GraphQL */ `
  subscription OnUpdateAnalytics {
    onUpdateAnalytics {
      id
      createdAt
      siteVisit
      topProduct
      updatedAt
    }
  }
`;
export const onDeleteAnalytics = /* GraphQL */ `
  subscription OnDeleteAnalytics {
    onDeleteAnalytics {
      id
      createdAt
      siteVisit
      topProduct
      updatedAt
    }
  }
`;
export const onCreatePage = /* GraphQL */ `
  subscription OnCreatePage {
    onCreatePage {
      id
      name
      createdAt
      updatedAt
      pages {
        items {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        nextToken
      }
      collections {
        items {
          id
          name
          index
          collectionPageId
          createdAt
          updatedAt
          page {
            id
            name
            createdAt
            updatedAt
          }
          products {
            nextToken
          }
          materials {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdatePage = /* GraphQL */ `
  subscription OnUpdatePage {
    onUpdatePage {
      id
      name
      createdAt
      updatedAt
      pages {
        items {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        nextToken
      }
      collections {
        items {
          id
          name
          index
          collectionPageId
          createdAt
          updatedAt
          page {
            id
            name
            createdAt
            updatedAt
          }
          products {
            nextToken
          }
          materials {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeletePage = /* GraphQL */ `
  subscription OnDeletePage {
    onDeletePage {
      id
      name
      createdAt
      updatedAt
      pages {
        items {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        nextToken
      }
      collections {
        items {
          id
          name
          index
          collectionPageId
          createdAt
          updatedAt
          page {
            id
            name
            createdAt
            updatedAt
          }
          products {
            nextToken
          }
          materials {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateCollection = /* GraphQL */ `
  subscription OnCreateCollection {
    onCreateCollection {
      id
      name
      index
      collectionPageId
      createdAt
      updatedAt
      page {
        id
        name
        createdAt
        updatedAt
        pages {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        collections {
          items {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      products {
        items {
          id
          productName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      materials {
        items {
          id
          materialName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCollection = /* GraphQL */ `
  subscription OnUpdateCollection {
    onUpdateCollection {
      id
      name
      index
      collectionPageId
      createdAt
      updatedAt
      page {
        id
        name
        createdAt
        updatedAt
        pages {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        collections {
          items {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      products {
        items {
          id
          productName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      materials {
        items {
          id
          materialName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCollection = /* GraphQL */ `
  subscription OnDeleteCollection {
    onDeleteCollection {
      id
      name
      index
      collectionPageId
      createdAt
      updatedAt
      page {
        id
        name
        createdAt
        updatedAt
        pages {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        collections {
          items {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      products {
        items {
          id
          productName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      materials {
        items {
          id
          materialName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateCollectionProduct = /* GraphQL */ `
  subscription OnCreateCollectionProduct {
    onCreateCollectionProduct {
      id
      productName
      collectionId
      createdAt
      updatedAt
      collection {
        id
        name
        index
        collectionPageId
        createdAt
        updatedAt
        page {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        products {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        materials {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const onUpdateCollectionProduct = /* GraphQL */ `
  subscription OnUpdateCollectionProduct {
    onUpdateCollectionProduct {
      id
      productName
      collectionId
      createdAt
      updatedAt
      collection {
        id
        name
        index
        collectionPageId
        createdAt
        updatedAt
        page {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        products {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        materials {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const onDeleteCollectionProduct = /* GraphQL */ `
  subscription OnDeleteCollectionProduct {
    onDeleteCollectionProduct {
      id
      productName
      collectionId
      createdAt
      updatedAt
      collection {
        id
        name
        index
        collectionPageId
        createdAt
        updatedAt
        page {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        products {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        materials {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct {
    onCreateProduct {
      id
      name
      description
      year
      designer
      createdAt
      updatedAt
      collections {
        items {
          id
          productName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      thumbnail {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      cad {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      spec {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      homepage {
        id
        index
        type
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          name
          createdAt
          updatedAt
          items {
            nextToken
          }
        }
      }
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct {
    onUpdateProduct {
      id
      name
      description
      year
      designer
      createdAt
      updatedAt
      collections {
        items {
          id
          productName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      thumbnail {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      cad {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      spec {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      homepage {
        id
        index
        type
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          name
          createdAt
          updatedAt
          items {
            nextToken
          }
        }
      }
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct {
    onDeleteProduct {
      id
      name
      description
      year
      designer
      createdAt
      updatedAt
      collections {
        items {
          id
          productName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      thumbnail {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      cad {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      spec {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      homepage {
        id
        index
        type
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          name
          createdAt
          updatedAt
          items {
            nextToken
          }
        }
      }
    }
  }
`;
export const onCreateCollectionMaterial = /* GraphQL */ `
  subscription OnCreateCollectionMaterial {
    onCreateCollectionMaterial {
      id
      materialName
      collectionId
      createdAt
      updatedAt
      collection {
        id
        name
        index
        collectionPageId
        createdAt
        updatedAt
        page {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        products {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        materials {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      material {
        id
        name
        description
        createdAt
        updatedAt
        collections {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const onUpdateCollectionMaterial = /* GraphQL */ `
  subscription OnUpdateCollectionMaterial {
    onUpdateCollectionMaterial {
      id
      materialName
      collectionId
      createdAt
      updatedAt
      collection {
        id
        name
        index
        collectionPageId
        createdAt
        updatedAt
        page {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        products {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        materials {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      material {
        id
        name
        description
        createdAt
        updatedAt
        collections {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const onDeleteCollectionMaterial = /* GraphQL */ `
  subscription OnDeleteCollectionMaterial {
    onDeleteCollectionMaterial {
      id
      materialName
      collectionId
      createdAt
      updatedAt
      collection {
        id
        name
        index
        collectionPageId
        createdAt
        updatedAt
        page {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        products {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        materials {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      material {
        id
        name
        description
        createdAt
        updatedAt
        collections {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const onCreateMaterial = /* GraphQL */ `
  subscription OnCreateMaterial {
    onCreateMaterial {
      id
      name
      description
      createdAt
      updatedAt
      collections {
        items {
          id
          materialName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      thumbnail {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      cad {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      spec {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
    }
  }
`;
export const onUpdateMaterial = /* GraphQL */ `
  subscription OnUpdateMaterial {
    onUpdateMaterial {
      id
      name
      description
      createdAt
      updatedAt
      collections {
        items {
          id
          materialName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      thumbnail {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      cad {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      spec {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
    }
  }
`;
export const onDeleteMaterial = /* GraphQL */ `
  subscription OnDeleteMaterial {
    onDeleteMaterial {
      id
      name
      description
      createdAt
      updatedAt
      collections {
        items {
          id
          materialName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      thumbnail {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      cad {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      spec {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
    }
  }
`;
export const onCreateAsset = /* GraphQL */ `
  subscription OnCreateAsset {
    onCreateAsset {
      id
      name
      url
      index
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      material {
        id
        name
        description
        createdAt
        updatedAt
        collections {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
      }
      aboutus {
        id
        name
        title
        subtitle
        description
        createdAt
        updatedAt
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateAsset = /* GraphQL */ `
  subscription OnUpdateAsset {
    onUpdateAsset {
      id
      name
      url
      index
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      material {
        id
        name
        description
        createdAt
        updatedAt
        collections {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
      }
      aboutus {
        id
        name
        title
        subtitle
        description
        createdAt
        updatedAt
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteAsset = /* GraphQL */ `
  subscription OnDeleteAsset {
    onDeleteAsset {
      id
      name
      url
      index
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      material {
        id
        name
        description
        createdAt
        updatedAt
        collections {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
      }
      aboutus {
        id
        name
        title
        subtitle
        description
        createdAt
        updatedAt
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateHomepage = /* GraphQL */ `
  subscription OnCreateHomepage {
    onCreateHomepage {
      id
      name
      createdAt
      updatedAt
      items {
        items {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateHomepage = /* GraphQL */ `
  subscription OnUpdateHomepage {
    onUpdateHomepage {
      id
      name
      createdAt
      updatedAt
      items {
        items {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteHomepage = /* GraphQL */ `
  subscription OnDeleteHomepage {
    onDeleteHomepage {
      id
      name
      createdAt
      updatedAt
      items {
        items {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateHomepageItem = /* GraphQL */ `
  subscription OnCreateHomepageItem {
    onCreateHomepageItem {
      id
      index
      type
      createdAt
      updatedAt
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      homepage {
        id
        name
        createdAt
        updatedAt
        items {
          items {
            id
            index
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateHomepageItem = /* GraphQL */ `
  subscription OnUpdateHomepageItem {
    onUpdateHomepageItem {
      id
      index
      type
      createdAt
      updatedAt
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      homepage {
        id
        name
        createdAt
        updatedAt
        items {
          items {
            id
            index
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteHomepageItem = /* GraphQL */ `
  subscription OnDeleteHomepageItem {
    onDeleteHomepageItem {
      id
      index
      type
      createdAt
      updatedAt
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      homepage {
        id
        name
        createdAt
        updatedAt
        items {
          items {
            id
            index
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateContactPage = /* GraphQL */ `
  subscription OnCreateContactPage {
    onCreateContactPage {
      id
      name
      email
      instagram
      sf_address
      sf_telephone
      sf_open_hours
      la_address
      la_telephone
      la_open_hours
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContactPage = /* GraphQL */ `
  subscription OnUpdateContactPage {
    onUpdateContactPage {
      id
      name
      email
      instagram
      sf_address
      sf_telephone
      sf_open_hours
      la_address
      la_telephone
      la_open_hours
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContactPage = /* GraphQL */ `
  subscription OnDeleteContactPage {
    onDeleteContactPage {
      id
      name
      email
      instagram
      sf_address
      sf_telephone
      sf_open_hours
      la_address
      la_telephone
      la_open_hours
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAboutus = /* GraphQL */ `
  subscription OnCreateAboutus {
    onCreateAboutus {
      id
      name
      title
      subtitle
      description
      createdAt
      updatedAt
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateAboutus = /* GraphQL */ `
  subscription OnUpdateAboutus {
    onUpdateAboutus {
      id
      name
      title
      subtitle
      description
      createdAt
      updatedAt
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteAboutus = /* GraphQL */ `
  subscription OnDeleteAboutus {
    onDeleteAboutus {
      id
      name
      title
      subtitle
      description
      createdAt
      updatedAt
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateContact = /* GraphQL */ `
  subscription OnCreateContact {
    onCreateContact {
      id
      type
      first_name
      last_name
      full_name
      primary_email
      primary_phone
      street_address
      city
      state
      country
      zip_code
      title
      vip
      vip_type
      createdAt
      updatedAt
      createdBy
      mailchimp_id
      sendNotification
      assignees {
        items {
          id
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      notes {
        items {
          id
          message
          owner
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      contactForms {
        items {
          id
          type
          firstName
          lastName
          email
          phone
          state
          zipcode
          message
          searchFirstName
          searchLastName
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      downloadForms {
        items {
          id
          type
          firstName
          lastName
          email
          productId
          productName
          searchFirstName
          searchLastName
          state
          zipcode
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      submissionForms {
        items {
          id
          type
          firstName
          lastName
          email
          phone
          title
          companyName
          streetAddress
          city
          state
          zipcode
          country
          website
          fileName
          fileUrl
          message
          productId
          productName
          preferredStore
          industry
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      tradeForms {
        items {
          id
          type
          firstName
          searchFirstName
          lastName
          searchLastName
          email
          title
          companyName
          searchCompanyName
          streetAddress
          city
          state
          zipcode
          website
          phone
          fileName
          fileUrl
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      divisions {
        items {
          id
          division {
            id
            label
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      pipeline {
        items {
          id
          type
          phase
          sale_amount
          createdAt
          updatedAt
          project_title
          description
          history {
            phase
            createdAt
            description
          }
          contactId
          employeeId
          searchName
          archived
          closedLostReason
          sendNotification
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      groups {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateContact = /* GraphQL */ `
  subscription OnUpdateContact {
    onUpdateContact {
      id
      type
      first_name
      last_name
      full_name
      primary_email
      primary_phone
      street_address
      city
      state
      country
      zip_code
      title
      vip
      vip_type
      createdAt
      updatedAt
      createdBy
      mailchimp_id
      sendNotification
      assignees {
        items {
          id
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      notes {
        items {
          id
          message
          owner
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      contactForms {
        items {
          id
          type
          firstName
          lastName
          email
          phone
          state
          zipcode
          message
          searchFirstName
          searchLastName
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      downloadForms {
        items {
          id
          type
          firstName
          lastName
          email
          productId
          productName
          searchFirstName
          searchLastName
          state
          zipcode
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      submissionForms {
        items {
          id
          type
          firstName
          lastName
          email
          phone
          title
          companyName
          streetAddress
          city
          state
          zipcode
          country
          website
          fileName
          fileUrl
          message
          productId
          productName
          preferredStore
          industry
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      tradeForms {
        items {
          id
          type
          firstName
          searchFirstName
          lastName
          searchLastName
          email
          title
          companyName
          searchCompanyName
          streetAddress
          city
          state
          zipcode
          website
          phone
          fileName
          fileUrl
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      divisions {
        items {
          id
          division {
            id
            label
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      pipeline {
        items {
          id
          type
          phase
          sale_amount
          createdAt
          updatedAt
          project_title
          description
          history {
            phase
            createdAt
            description
          }
          contactId
          employeeId
          searchName
          archived
          closedLostReason
          sendNotification
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      groups {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteContact = /* GraphQL */ `
  subscription OnDeleteContact {
    onDeleteContact {
      id
      type
      first_name
      last_name
      full_name
      primary_email
      primary_phone
      street_address
      city
      state
      country
      zip_code
      title
      vip
      vip_type
      createdAt
      updatedAt
      createdBy
      mailchimp_id
      sendNotification
      assignees {
        items {
          id
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      notes {
        items {
          id
          message
          owner
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      contactForms {
        items {
          id
          type
          firstName
          lastName
          email
          phone
          state
          zipcode
          message
          searchFirstName
          searchLastName
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      downloadForms {
        items {
          id
          type
          firstName
          lastName
          email
          productId
          productName
          searchFirstName
          searchLastName
          state
          zipcode
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      submissionForms {
        items {
          id
          type
          firstName
          lastName
          email
          phone
          title
          companyName
          streetAddress
          city
          state
          zipcode
          country
          website
          fileName
          fileUrl
          message
          productId
          productName
          preferredStore
          industry
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      tradeForms {
        items {
          id
          type
          firstName
          searchFirstName
          lastName
          searchLastName
          email
          title
          companyName
          searchCompanyName
          streetAddress
          city
          state
          zipcode
          website
          phone
          fileName
          fileUrl
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      divisions {
        items {
          id
          division {
            id
            label
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      pipeline {
        items {
          id
          type
          phase
          sale_amount
          createdAt
          updatedAt
          project_title
          description
          history {
            phase
            createdAt
            description
          }
          contactId
          employeeId
          searchName
          archived
          closedLostReason
          sendNotification
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      groups {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreatePipeline = /* GraphQL */ `
  subscription OnCreatePipeline {
    onCreatePipeline {
      id
      type
      phase
      sale_amount
      createdAt
      updatedAt
      project_title
      description
      history {
        phase
        createdAt
        editedBy {
          id
          name
        }
        description
      }
      contactId
      employeeId
      searchName
      archived
      closedLostReason
      sendNotification
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdatePipeline = /* GraphQL */ `
  subscription OnUpdatePipeline {
    onUpdatePipeline {
      id
      type
      phase
      sale_amount
      createdAt
      updatedAt
      project_title
      description
      history {
        phase
        createdAt
        editedBy {
          id
          name
        }
        description
      }
      contactId
      employeeId
      searchName
      archived
      closedLostReason
      sendNotification
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeletePipeline = /* GraphQL */ `
  subscription OnDeletePipeline {
    onDeletePipeline {
      id
      type
      phase
      sale_amount
      createdAt
      updatedAt
      project_title
      description
      history {
        phase
        createdAt
        editedBy {
          id
          name
        }
        description
      }
      contactId
      employeeId
      searchName
      archived
      closedLostReason
      sendNotification
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany {
    onCreateCompany {
      id
      type
      name
      vip
      vip_type
      street_address
      city
      state
      country
      zip_code
      email
      phone
      website
      mailchimp_id
      sendNotification
      assignees {
        items {
          id
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      notes {
        items {
          id
          message
          owner
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      messages {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      createdBy
      divisions {
        items {
          id
          division {
            id
            label
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      contacts {
        items {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          divisions {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      assignedTo {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      groups {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany {
    onUpdateCompany {
      id
      type
      name
      vip
      vip_type
      street_address
      city
      state
      country
      zip_code
      email
      phone
      website
      mailchimp_id
      sendNotification
      assignees {
        items {
          id
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      notes {
        items {
          id
          message
          owner
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      messages {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      createdBy
      divisions {
        items {
          id
          division {
            id
            label
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      contacts {
        items {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          divisions {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      assignedTo {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      groups {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany {
    onDeleteCompany {
      id
      type
      name
      vip
      vip_type
      street_address
      city
      state
      country
      zip_code
      email
      phone
      website
      mailchimp_id
      sendNotification
      assignees {
        items {
          id
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      notes {
        items {
          id
          message
          owner
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      messages {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      createdBy
      divisions {
        items {
          id
          division {
            id
            label
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      contacts {
        items {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          divisions {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      assignedTo {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      groups {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateEmployee = /* GraphQL */ `
  subscription OnCreateEmployee {
    onCreateEmployee {
      id
      name
      jobTitle
      email
      assignedContacts {
        items {
          id
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      assignedCompanies {
        items {
          id
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      activity {
        items {
          id
          activityId
          type
          creatorId
          createdAt
          action
          activityType
          payload {
            name
            note
            target
            targetId
            recipientOthers
            subject
            date
          }
          updatedAt
          creator {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      messagesSent {
        items {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      messagesReceived {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      calendarPermissions {
        items {
          id
          type
          rule
          permissionUserId
          viewActive
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      reportFormData
      status
      createdAt
      updatedAt
      contacts {
        items {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          divisions {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      pipeline {
        items {
          id
          type
          phase
          sale_amount
          createdAt
          updatedAt
          project_title
          description
          history {
            phase
            createdAt
            description
          }
          contactId
          employeeId
          searchName
          archived
          closedLostReason
          sendNotification
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      companys {
        items {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      googleCalendarToken {
        id
        service
        tokenUserId
        access_token
        refresh_token
        scope
        expiry_date
        token_type
        metadata
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      calendars {
        items {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateEmployee = /* GraphQL */ `
  subscription OnUpdateEmployee {
    onUpdateEmployee {
      id
      name
      jobTitle
      email
      assignedContacts {
        items {
          id
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      assignedCompanies {
        items {
          id
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      activity {
        items {
          id
          activityId
          type
          creatorId
          createdAt
          action
          activityType
          payload {
            name
            note
            target
            targetId
            recipientOthers
            subject
            date
          }
          updatedAt
          creator {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      messagesSent {
        items {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      messagesReceived {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      calendarPermissions {
        items {
          id
          type
          rule
          permissionUserId
          viewActive
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      reportFormData
      status
      createdAt
      updatedAt
      contacts {
        items {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          divisions {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      pipeline {
        items {
          id
          type
          phase
          sale_amount
          createdAt
          updatedAt
          project_title
          description
          history {
            phase
            createdAt
            description
          }
          contactId
          employeeId
          searchName
          archived
          closedLostReason
          sendNotification
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      companys {
        items {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      googleCalendarToken {
        id
        service
        tokenUserId
        access_token
        refresh_token
        scope
        expiry_date
        token_type
        metadata
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      calendars {
        items {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteEmployee = /* GraphQL */ `
  subscription OnDeleteEmployee {
    onDeleteEmployee {
      id
      name
      jobTitle
      email
      assignedContacts {
        items {
          id
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      assignedCompanies {
        items {
          id
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      activity {
        items {
          id
          activityId
          type
          creatorId
          createdAt
          action
          activityType
          payload {
            name
            note
            target
            targetId
            recipientOthers
            subject
            date
          }
          updatedAt
          creator {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      messagesSent {
        items {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      messagesReceived {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      calendarPermissions {
        items {
          id
          type
          rule
          permissionUserId
          viewActive
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      reportFormData
      status
      createdAt
      updatedAt
      contacts {
        items {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          divisions {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      pipeline {
        items {
          id
          type
          phase
          sale_amount
          createdAt
          updatedAt
          project_title
          description
          history {
            phase
            createdAt
            description
          }
          contactId
          employeeId
          searchName
          archived
          closedLostReason
          sendNotification
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      companys {
        items {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      googleCalendarToken {
        id
        service
        tokenUserId
        access_token
        refresh_token
        scope
        expiry_date
        token_type
        metadata
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      calendars {
        items {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateToken = /* GraphQL */ `
  subscription OnCreateToken {
    onCreateToken {
      id
      service
      tokenUserId
      access_token
      refresh_token
      scope
      expiry_date
      token_type
      metadata
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateToken = /* GraphQL */ `
  subscription OnUpdateToken {
    onUpdateToken {
      id
      service
      tokenUserId
      access_token
      refresh_token
      scope
      expiry_date
      token_type
      metadata
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteToken = /* GraphQL */ `
  subscription OnDeleteToken {
    onDeleteToken {
      id
      service
      tokenUserId
      access_token
      refresh_token
      scope
      expiry_date
      token_type
      metadata
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateGroupContact = /* GraphQL */ `
  subscription OnCreateGroupContact {
    onCreateGroupContact {
      id
      mailchimp_status
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
        createdAt
        updatedAt
        contacts {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateGroupContact = /* GraphQL */ `
  subscription OnUpdateGroupContact {
    onUpdateGroupContact {
      id
      mailchimp_status
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
        createdAt
        updatedAt
        contacts {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteGroupContact = /* GraphQL */ `
  subscription OnDeleteGroupContact {
    onDeleteGroupContact {
      id
      mailchimp_status
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
        createdAt
        updatedAt
        contacts {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateGroupCompany = /* GraphQL */ `
  subscription OnCreateGroupCompany {
    onCreateGroupCompany {
      id
      mailchimp_status
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
        createdAt
        updatedAt
        contacts {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateGroupCompany = /* GraphQL */ `
  subscription OnUpdateGroupCompany {
    onUpdateGroupCompany {
      id
      mailchimp_status
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
        createdAt
        updatedAt
        contacts {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteGroupCompany = /* GraphQL */ `
  subscription OnDeleteGroupCompany {
    onDeleteGroupCompany {
      id
      mailchimp_status
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
        createdAt
        updatedAt
        contacts {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateGroup = /* GraphQL */ `
  subscription OnCreateGroup {
    onCreateGroup {
      id
      name
      mailchimp_list_id
      mailchimp_webhook_id
      mailchimp_tag_id
      mailchimp_tag_name
      mailchimp_newsletter_id
      type
      createdAt
      updatedAt
      contacts {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      companies {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup {
    onUpdateGroup {
      id
      name
      mailchimp_list_id
      mailchimp_webhook_id
      mailchimp_tag_id
      mailchimp_tag_name
      mailchimp_newsletter_id
      type
      createdAt
      updatedAt
      contacts {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      companies {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteGroup = /* GraphQL */ `
  subscription OnDeleteGroup {
    onDeleteGroup {
      id
      name
      mailchimp_list_id
      mailchimp_webhook_id
      mailchimp_tag_id
      mailchimp_tag_name
      mailchimp_newsletter_id
      type
      createdAt
      updatedAt
      contacts {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      companies {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateMailchimpConfig = /* GraphQL */ `
  subscription OnCreateMailchimpConfig {
    onCreateMailchimpConfig {
      id
      dc
      accountname
      login_url
      api_endpoint
      access_token
      mailchimp_user_id
      mailchimp_user_email
      cognito_user_email
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMailchimpConfig = /* GraphQL */ `
  subscription OnUpdateMailchimpConfig {
    onUpdateMailchimpConfig {
      id
      dc
      accountname
      login_url
      api_endpoint
      access_token
      mailchimp_user_id
      mailchimp_user_email
      cognito_user_email
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMailchimpConfig = /* GraphQL */ `
  subscription OnDeleteMailchimpConfig {
    onDeleteMailchimpConfig {
      id
      dc
      accountname
      login_url
      api_endpoint
      access_token
      mailchimp_user_id
      mailchimp_user_email
      cognito_user_email
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGmailConfig = /* GraphQL */ `
  subscription OnCreateGmailConfig {
    onCreateGmailConfig {
      id
      cognito_user_email
      gmail_address
      access_token
      refresh_token
      scope
      token_type
      expiry_date
      signature
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGmailConfig = /* GraphQL */ `
  subscription OnUpdateGmailConfig {
    onUpdateGmailConfig {
      id
      cognito_user_email
      gmail_address
      access_token
      refresh_token
      scope
      token_type
      expiry_date
      signature
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGmailConfig = /* GraphQL */ `
  subscription OnDeleteGmailConfig {
    onDeleteGmailConfig {
      id
      cognito_user_email
      gmail_address
      access_token
      refresh_token
      scope
      token_type
      expiry_date
      signature
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCalendar = /* GraphQL */ `
  subscription OnCreateCalendar {
    onCreateCalendar {
      id
      name
      primary
      calendarUserId
      color
      googleCalendarId
      googleChannelId
      googleResourceId
      googleSyncToken
      googleName
      permissions {
        items {
          id
          type
          rule
          permissionUserId
          viewActive
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      events {
        items {
          id
          source
          title
          startDate
          startTime
          startTimeZoneName
          startTimeZoneHour
          endDate
          endTime
          endTimeZoneName
          endTimeZoneHour
          allDay
          location
          description
          creatorId
          eventCalendarId
          googleEventId
          createdAt
          updatedAt
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          attendees {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCalendar = /* GraphQL */ `
  subscription OnUpdateCalendar {
    onUpdateCalendar {
      id
      name
      primary
      calendarUserId
      color
      googleCalendarId
      googleChannelId
      googleResourceId
      googleSyncToken
      googleName
      permissions {
        items {
          id
          type
          rule
          permissionUserId
          viewActive
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      events {
        items {
          id
          source
          title
          startDate
          startTime
          startTimeZoneName
          startTimeZoneHour
          endDate
          endTime
          endTimeZoneName
          endTimeZoneHour
          allDay
          location
          description
          creatorId
          eventCalendarId
          googleEventId
          createdAt
          updatedAt
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          attendees {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCalendar = /* GraphQL */ `
  subscription OnDeleteCalendar {
    onDeleteCalendar {
      id
      name
      primary
      calendarUserId
      color
      googleCalendarId
      googleChannelId
      googleResourceId
      googleSyncToken
      googleName
      permissions {
        items {
          id
          type
          rule
          permissionUserId
          viewActive
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      events {
        items {
          id
          source
          title
          startDate
          startTime
          startTimeZoneName
          startTimeZoneHour
          endDate
          endTime
          endTimeZoneName
          endTimeZoneHour
          allDay
          location
          description
          creatorId
          eventCalendarId
          googleEventId
          createdAt
          updatedAt
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          attendees {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent {
    onCreateEvent {
      id
      source
      title
      startDate
      startTime
      startTimeZoneName
      startTimeZoneHour
      endDate
      endTime
      endTimeZoneName
      endTimeZoneHour
      allDay
      location
      description
      creatorId
      eventCalendarId
      googleEventId
      createdAt
      updatedAt
      calendar {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendees {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent {
    onUpdateEvent {
      id
      source
      title
      startDate
      startTime
      startTimeZoneName
      startTimeZoneHour
      endDate
      endTime
      endTimeZoneName
      endTimeZoneHour
      allDay
      location
      description
      creatorId
      eventCalendarId
      googleEventId
      createdAt
      updatedAt
      calendar {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendees {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent {
    onDeleteEvent {
      id
      source
      title
      startDate
      startTime
      startTimeZoneName
      startTimeZoneHour
      endDate
      endTime
      endTimeZoneName
      endTimeZoneHour
      allDay
      location
      description
      creatorId
      eventCalendarId
      googleEventId
      createdAt
      updatedAt
      calendar {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendees {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateEventAttendee = /* GraphQL */ `
  subscription OnCreateEventAttendee {
    onCreateEventAttendee {
      id
      typeAttendee
      eventAttendeeEventId
      createdAt
      updatedAt
      attendeeContact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendeeCompany {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendeeEmployee {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      event {
        id
        source
        title
        startDate
        startTime
        startTimeZoneName
        startTimeZoneHour
        endDate
        endTime
        endTimeZoneName
        endTimeZoneHour
        allDay
        location
        description
        creatorId
        eventCalendarId
        googleEventId
        createdAt
        updatedAt
        calendar {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        attendees {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateEventAttendee = /* GraphQL */ `
  subscription OnUpdateEventAttendee {
    onUpdateEventAttendee {
      id
      typeAttendee
      eventAttendeeEventId
      createdAt
      updatedAt
      attendeeContact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendeeCompany {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendeeEmployee {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      event {
        id
        source
        title
        startDate
        startTime
        startTimeZoneName
        startTimeZoneHour
        endDate
        endTime
        endTimeZoneName
        endTimeZoneHour
        allDay
        location
        description
        creatorId
        eventCalendarId
        googleEventId
        createdAt
        updatedAt
        calendar {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        attendees {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteEventAttendee = /* GraphQL */ `
  subscription OnDeleteEventAttendee {
    onDeleteEventAttendee {
      id
      typeAttendee
      eventAttendeeEventId
      createdAt
      updatedAt
      attendeeContact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          assignees {
            nextToken
          }
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          divisions {
            nextToken
          }
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendeeCompany {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        assignees {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        divisions {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          assignedContacts {
            nextToken
          }
          assignedCompanies {
            nextToken
          }
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendeeEmployee {
        id
        name
        jobTitle
        email
        assignedContacts {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedCompanies {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      event {
        id
        source
        title
        startDate
        startTime
        startTimeZoneName
        startTimeZoneHour
        endDate
        endTime
        endTimeZoneName
        endTimeZoneHour
        allDay
        location
        description
        creatorId
        eventCalendarId
        googleEventId
        createdAt
        updatedAt
        calendar {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        attendees {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
